#root .input-wrapper > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .input-wrapper > .title-container > .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}
#root .input-wrapper > .title-container > .sub-title {
  color: #FFF;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}
#root .input-wrapper > .title-container > .error-text {
  color: #FF0000;
}
#root .input-wrapper > .title-container > .number-text {
  color: #234534;
}
#root .input-wrapper input {
  width: 320px;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #DBDBDB;
}
#root .input-wrapper input[type=number]::-webkit-outer-spin-button,
#root .input-wrapper input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}