#root .home-page-main {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
#root .home-page-main > .wrapper {
  padding: 0px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 93px;
  padding-bottom: 25px;
}
#root .home-page-main > .wrapper > .main-img-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .home-page-main > .wrapper > .main-img-container img {
  width: 220px;
  height: 103px;
}
#root .home-page-main > .wrapper > .form-container {
  width: 100%;
  padding: 0px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  row-gap: 20px;
  margin-top: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .home-page-main > .wrapper > .form-container .agreement-check {
  width: 320px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  padding: 15px 16px;
  border: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #54585A;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  background-color: white;
  border: 1px solid #DBDBDB;
}
#root .home-page-main > .wrapper > .form-container .agreement-check .check-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #DBDBDB;
}
#root .home-page-main > .wrapper > .form-container .agreement-check-box {
  color: #234534;
  background-color: rgba(35, 69, 52, 0.1);
  font-weight: 700;
}
#root .home-page-main > .wrapper > .form-container .gender-choice-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .home-page-main > .wrapper > .form-container .gender-choice-container .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .home-page-main > .wrapper > .form-container .gender-choice-container .title-container .error-text {
  color: #FFE48F;
  font-size: 14px;
  font-weight: 400;
}
#root .home-page-main > .wrapper > .form-container .gender-choice-container .label-title {
  color: white;
  font-weight: 700;
}
#root .home-page-main > .wrapper > .form-container .gender-choice-container .gender-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4px;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .home-page-main > .wrapper > .form-container .submit-button {
  width: 320px;
  height: 50px;
  background: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 45px;
  cursor: pointer;
  font-size: 16px;
}
#root .home-page-main > .wrapper > .form-container .submit-enabled {
  background-color: #234534;
  color: white;
}