@import url("https://webfontworld.github.io/pretendard/Pretendard.css");
@-webkit-keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
main {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "pretendard", sans-serif;
  color: black;
  -webkit-overflow-scrolling: touch;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Pretendard", sans-serif;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}