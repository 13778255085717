#root .gender-choice {
  cursor: pointer;
  padding: 15px 16px;
  width: 155px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #54585A;
  background-color: white;
  border: 1px solid #DBDBDB;
}
#root .gender-choice .check-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #DBDBDB;
}
#root .gender-choice-check {
  background-color: rgba(210, 230, 224, 0.8705882353);
  color: #234534;
  background-color: rgba(35, 69, 52, 0.1);
  font-weight: 700;
}