#root {
    .sucess-main-page {
        max-width: 500px;
        margin: auto;
        &>.wrapper {
            display: flex;
            flex-direction: column;
            height: 100vh;
            padding-top: 201px;
            padding-bottom: 27px;
            box-sizing: border-box;
            &>.img-container {
                display: flex;
                justify-content: center;
                margin: 0 auto;
                img {
                    width: 172px;
                    height: 81px;
                }
            }
            &>.text-container {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
               &>.title{
                    margin-top: 29px;
                    text-align: center;
                    line-height: 150%;
                    white-space: pre-wrap;
                    font-size: 24px;
                    font-weight: 700;
               }
                &>.subtitle {
                        text-align: center;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 150%;
                        white-space: pre-wrap;
                    }
            }
           &>.mainbtn-container{
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            row-gap: 7px;
            &>.btn-container {
                    display: flex;
                    justify-content: center;
            
                    &>.main-button {
                        width: 320px;
                        height: 50px;
                        margin-top: auto;
                        border-radius: 5px;
                        background: #234534;
                        color: white;
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 700;
            
                    }
                }
            
                &>.kakaotalk-btn {
                    text-align: center;
                    margin-top: 29px;
            
                    a {
                        img {
                            width: 320px;
                            height: 50px;
            
                        }
                    }
                }
           }
        }
    }

}